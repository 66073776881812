import React, { useState, useEffect } from 'react'
import { getToken, getUserInfo } from './utils/auth';
import { Layout, Row, Col, Button, Modal, Input, DatePicker, Switch, message  } from 'antd'
import Frame from './Frame';
import request from './utils/request';
import { fetchAuth, fetchUsuarios } from './actions'
import { connect } from 'react-redux'
import moment from 'moment'


const PerfilManager = ({ auth, usuarios, match, dispatch }) => {

    const [usuario, setUsuario] = useState()
    const [modal, setModal] = useState(false)
    const [nombre, setNombre] = useState("")
    const [apellidos, setApellidos] = useState("")
    const [nif, setNif] = useState("")
    const [nuss, setNuss] = useState("")
    const [iniciocontrato, setIniciocontrato] = useState("")
    const [fincontrato, setFincontrato] = useState("")
    const [activo, setActivo] = useState("")
    
    useEffect(() => {
        const userMatch = usuarios.find(u => u.username === match.params.usuario)
        if(userMatch){
            setUsuario(userMatch)
            setNombre(userMatch.nombre)
            setApellidos(userMatch.apellidos)
            setNif(userMatch.nif)
            setNuss(userMatch.nuss)
            setIniciocontrato(userMatch.iniciocontrato ? moment(userMatch.iniciocontrato) : null)
            setFincontrato(userMatch.fincontrato ? moment(userMatch.fincontrato) : null)
            setActivo(!userMatch.blocked)
        }
    }, [usuarios])

    const guardarDatos = () => {
        request("/users/" + usuario._id, {
            method: "PUT",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: {
                usuario,
                nombre,
                apellidos,
                nif,
                nuss,
                iniciocontrato,
                fincontrato,
                activo
            }
        }).then(data => {
            message.success("Los datos se han guardado correctamente")
            dispatch(fetchUsuarios())
        }).catch(err => {
            console.log({ err })
            message.error("Ocurrió un error durante el guardado de los datos")
        })
        setModal(false)
    }

    return (
        <Layout style={{height:"100vh"}}>
            <Frame isLogged={ getToken() ? true : false }>
                <h3>{ usuario && usuario.username }</h3>
                <Row>
                    <Col sm={24} md={8}>
                        { usuario ? 
                            <img 
                                src={usuario.avatar && (process.env.REACT_APP_API_URL + usuario.avatar.url)} 
                                alt={`Foto de perfil de ${usuario.username}`}
                                style={{ width: "100%", borderRadius: 4 }}
                            /> : 
                            <div></div> 
                        }
                    </Col>
                    <Col sm={24} md={16} style={{ paddingLeft: 20 }}>
                        <p>Nombre: {usuario ? (usuario.nombre + " " + usuario.apellidos) : ""}</p>
                        <p>NIF: {(usuario && usuario.nif) ? usuario.nif : "Sin asignar"}</p>
                        <p>NUSS: {(usuario && usuario.nuss) ? usuario.nuss : "Sin asignar"}</p>
                        <p>Fecha de inicio de contrato: {(usuario && usuario.iniciocontrato) ? moment(usuario.iniciocontrato).format("YYYY-MM-DD") : "Sin asignar"}</p>
                        { usuario && usuario.finalcontrato && <p>Fecha de fin de contrato: {moment(usuario.finalcontrato).format("YYYY-MM-DD")}</p> }
                        <Button onClick={() => setModal(true)}>Editar datos</Button>
                    </Col>
                </Row>
                <Modal
                    visible={modal}
                    onOk={() => guardarDatos()}
                    onCancel={() => setModal(false)}
                    okText="Guardar datos"
                    cancelText="Cancelar"
                >
                    <label htmlFor="nombre">Nombre </label>
                    <Input style={{ marginBottom: "1em", marginTop: 5 }} name="nombre" value={nombre} onChange={e => setNombre(e.target.value)} />
                    <label htmlFor="apellidos">Apellidos </label>
                    <Input style={{ marginBottom: "1em", marginTop: 5 }} name="apellidos" value={apellidos} onChange={e => setApellidos(e.target.value)} />
                    <label htmlFor="nif">NIF </label>
                    <Input style={{ marginBottom: "1em", marginTop: 5 }} name="nif" value={nif} onChange={e => setNif(e.target.value)} />
                    <label htmlFor="nuss">NUSS </label>
                    <Input style={{ marginBottom: "1em", marginTop: 5 }} name="nuss" value={nuss} onChange={e => setNuss(e.target.value)} />
                    <label htmlFor="iniciocontrato">Fecha de inicio de contrato </label>
                    <DatePicker 
                        style={{ width: "100%", marginBottom: "1em", marginTop: 5 }} 
                        placeholder="Selecciona una fecha"
                        value={iniciocontrato}
                        onChange={e => setIniciocontrato(e)}
                    />
                    <label htmlFor="fincontrato">Fecha de final de contrato </label>
                    <DatePicker 
                        style={{ width: "100%", marginBottom: "1em", marginTop: 5 }} 
                        placeholder="Selecciona una fecha"
                        value={fincontrato}
                        onChange={e => setFincontrato(e)}
                    />
                    <label htmlFor="activo" style={{ marginRight: "1em" }}>Usuario activo </label>
                    <Switch checked={activo} onChange={e => setActivo(e)} />
                </Modal>
            </Frame>
        </Layout>
    )
}

const mapStateToProps = state => ({
    auth: state.auth,
    usuarios: state.usuarios
})

export default connect(mapStateToProps)(PerfilManager)