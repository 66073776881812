import React, { Component } from 'react'
import { getToken } from './utils/auth';
import { Layout, Collapse, Form, Switch, Card, message, Input } from 'antd'
import Frame from './Frame';
import ModuloFestivos from './modulosConfig/ModuloFestivos';
import ModuloRegistros from './modulosConfig/ModuloRegistros';
import { connect } from 'react-redux'
import { Button } from 'antd/lib/radio';
import request from './utils/request';
import { fetchConfig } from './actions';


class Configuracion extends Component{

    state = {
        modoReunion: false,
        comentarioDefault: ""
    }

    componentDidMount(){
        if(Array.isArray(this.props.config) && this.props.config[0]){
            const config = this.props.config[0]
            this.setState({ 
                modoReunion: config.modoReunion,
                comentarioDefault: config.comentarioDefault 
            })
        }
    }
    componentDidUpdate(prevProps){
        if(Array.isArray(this.props.config) && (this.props.config !== prevProps.config)){
            const config = this.props.config[0]
            this.setState({ 
                modoReunion: config.modoReunion,
                comentarioDefault: config.comentarioDefault 
            })
        }
    }

    aplicarCambios = () => {
        request("/configs/" + this.props.config[0]._id, {
            method: "PUT",
            body: {
                modoReunion: this.state.modoReunion,
                comentarioDefault: this.state.comentarioDefault
            }
        }).then(data => {
            console.log(data)
            message.success("La configuración se guardó correctamente")
            this.props.dispatch(fetchConfig(this.props.auth.equipo._id))
        }).catch(err => {
            console.log(err)
            message.error("Ocurrió un error durante el guardado de la configuración")
        })
    }

    render(){
        const Panel = Collapse.Panel;
        return(
            <Layout style={{height:"100vh"}}>
                <Frame isLogged={ getToken() ? true : false }>
                    <h1>Configuración</h1>
                    <Collapse>
                        <Panel header="Añadir registros">
                            <ModuloRegistros />
                        </Panel>
                    </Collapse>
                    <Collapse>
                        <Panel header="Gestionar festivos">
                            <ModuloFestivos />
                        </Panel>
                    </Collapse>

                    <Card>
                    <Form.Item label="Modo Reunión">
                        <Switch checked={this.state.modoReunion} onChange={evt => this.setState({ modoReunion: evt})} />
                    </Form.Item>
                    <Form.Item label="Comentario por defecto al solicitar días libres y remotos">
                        <Input type="text" onChange={e => this.setState({ comentarioDefault: e.target.value })} value={this.state.comentarioDefault} />
                    </Form.Item>
                    <Button onClick={this.aplicarCambios}>Aplicar</Button>
                    </Card>
                </Frame>
            </Layout>
        )
    }
}

const mapStateToProps = state => ({
    config: state.config,
    auth: state.auth
})

export default connect(mapStateToProps)(Configuracion)