import React, { useState } from 'react'

const Loader = ({ perc }) => {
    const [opacity, setOpacity] = useState(1)
    if(perc === 1){
        setTimeout(async () => {
            setOpacity(0)
        }, 250)
    }
    return (
        <div style={{ backgroundColor: "#191919", width: "100vw", height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", transition: "all 250ms", opacity }}>
            <img src="https://we.blacknosaur.com/logo.png" alt="Blacknosaur Logo" style={{ filter: "invert(1)", height: 100, marginBottom: 20 }}></img>
            <div className="progressbarita">
                <div className="progressinnerito" style={{ width: perc*100 + "%" }}></div>
            </div>
        </div>
    )
}

export default Loader