export const CARGAR_USUARIOS = "CARGAR_USUARIOS"
export const CARGAR_CALENDARIO = "CARGAR_CALENDARIO"
export const CARGAR_ES = "CARGAR_ES"
export const CARGAR_DOCUMENTOS = "CARGAR_DOCUMENTOS"
export const CARGAR_GRUPOS = "CARGAR_GRUPOS"
export const CARGAR_PWS = "CARGAR_PWS"
export const CARGAR_AUTH = "CARGAR_AUTH"
export const CARGAR_ENTRADAS = "CARGAR_ENTRADAS"
export const CARGAR_CONFIG = "CARGAR_CONFIG"
export const CAMBIAR_BLUE_COLLAR = "CAMBIAR_BLUE_COLLAR"
export const CAMBIAR_MES_ES = "CAMBIAR_MES_ES"
export const CAMBIAR_LOADING = "CAMBIAR_LOADING"
export const FALLO_CARGA = "FALLO_CARGA"