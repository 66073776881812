import { 
    CAMBIAR_LOADING
} from "../constants/action-types";

export default function loadingReducer(state = 0, action) {
    switch (action.type) {
        case CAMBIAR_LOADING:
            return action.payload;
        default:
            return state;
    }
}