
import { message } from 'antd'
import { falloCarga } from '../actions'
import moment from 'moment'

export const mayusculizer = str => typeof str === "string" ? str.replace(/\s|-/g, " ,")
    .split(",")
    .map(c => c.slice(0, 1).toUpperCase() + c.slice(1).toLowerCase())
    .reduce((a, b) => a + b, "") : ""

export const redondear = (dato, n = 0) => 
    parseInt(dato * Math.pow(10, n), 10) / Math.pow(10, n)

export const gestionaError = (err, dispatch, chivato) => {
    if(err.response){
        console.log(err.response.payload.error + " - " + err.response.payload.message)
        if(err.response.status === 403){
            message.error("No tienes permiso para acceder a ese contenido")
            dispatch(falloCarga({ error: 403, redirectTo: "/", chivato }))
        }
        else if(err.response.status === 401){
            message.error("La sesión ha caducado o los datos de autenticación son incorrectos")
            localStorage.clear()
            sessionStorage.clear()
            dispatch(falloCarga({ error: 401, redirectTo: "/login", chivato }))
        }
    }
    else{
        console.log(err)
    }
}

export const calcularVacaciones = user => {
    const diasAno = 365 + (moment().isLeapYear() ? 1 : 0)
    const duracionActualContrato = user.iniciocontrato ? moment().diff(moment(user.iniciocontrato), "days") : undefined
    const c = (user.correccion && user.correccion.filter(c => parseInt(c.ano, 10) === moment().year()).length > 0) ? user.correccion.find(c => parseInt(c.ano, 10) === moment().year()) : { correccion: 0 }

    const diasTotales = duracionActualContrato ? (duracionActualContrato <= diasAno ? (Math.trunc((duracionActualContrato / diasAno) * 23) + (parseInt(c.correccion, 10) || 0)) : (23 + (parseInt(c.correccion, 10) || 0))) : " - "
    const diasLibresFuturos = user.dias ? user.dias.filter(d => moment(d.fecha).isSame(moment(), "year") && d.tipo === "libre" && d.aprobado && moment(d.fecha).isAfter(moment())).length : ""
    const diasLibresPasados = user.dias ? user.dias.filter(d => moment(d.fecha).isSame(moment(), "year") && d.tipo === "libre" && d.aprobado && moment(d.fecha).isBetween(moment().startOf("year"), moment(), "days", "[]")).length : ""
    const diasLibresPendientes = user.dias ? user.dias.filter(d => moment(d.fecha).isSame(moment(), "year") && d.tipo === "libre" && !d.aprobado && moment(d.fecha).isAfter(moment())).length : ""
    const diasQuedan = diasTotales - diasLibresFuturos - diasLibresPasados
    const obj = {
        diasTotales,
        diasQuedan,
        diasLibresFuturos,
        diasLibresPasados,
        diasLibresPendientes
    }
    // if(!u){ this.setState({ ...obj }) }
    return obj
}