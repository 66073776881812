import React, { Component } from 'react'
import { Layout, Form, Calendar, Modal, message, Avatar, Button, Popover, Radio, Row, Table, Popconfirm, Tag, Statistic, Icon } from 'antd'
import moment from 'moment';
import { getHeaders, getUserInfo, getToken } from './utils/auth';
import request from './utils/request';
import Frame from './Frame';
import PrivateComponent from './PrivateComponent';
import { connect } from 'react-redux'
import { fetchCalendario, fetchAuth, fetchUsuarios } from './actions';
import { uniqBy } from 'lodash'
import { mayusculizer, calcularVacaciones } from './utils/func';

class Calendario extends Component{
    state = {
        modalPedirDia: false,
        dias: [],
        diasFilter: [],
        diasPorAprobar: [],
        diasLibresPorAprobar: [],
        diasRemotosPorAprobar: [],
        festivos: [],
        festivosArrUTC: [],
        columns: [],
        extraColLibre: [],
        blockSolicitar: false,
        sel1: "libres",
        sel2: "global",
        tipoDiaPedido: "libre",
        modalInfoDia: false,
        infoDia: {}
    }
    comDiaPedido = React.createRef();

    componentDidMount(){
        
        const columns = [{
            title: 'Nombre de usuario',
            key: 'user',
            render: r => r.user && r.user.username
        }, {
            title: 'Tipo',
            dataIndex: 'tipo',
            key: 'tipo',
            render: row => mayusculizer(row)
        }, {
            title: 'Fecha',
            key: 'fecha',
            render: row => moment(row.fecha).format("YYYY-MM-DD")
        }, {
            title: 'Nota',
            key: 'nota',
            render: row => <div style={{ maxWidth: 400 }}>{row.comentario}</div>
        }, {
            title: 'Acción',
            key: 'action',
            render: (text, record) => (
              <span style={{ minWidth: 140 }}>
                <Popconfirm title={`¿Estás seguro de que deseas aceptar la solicitud de día ${record.tipo} para el día ${moment(record.fecha).format("YYYY-MM-DD")} a ${record.user && record.user.username}?`} onConfirm={evt => this.responderSolicitud(record, true)}>
                    <Tag color="green" key={`${record._id}_aceptar`}>Aprobar</Tag>
                </Popconfirm>
                <Popconfirm title={`¿Estás seguro de que deseas denegar la solicitud de día ${record.tipo} para el día ${moment(record.fecha).format("YYYY-MM-DD")} a ${record.user && record.user.username}?`} onConfirm={evt => this.responderSolicitud(record, false)}>
                    <Tag color="volcano" key={`${record._id}_denegar`}>Denegar</Tag>
                </Popconfirm>
              </span>
            ),
        }]
        const extraColLibre = [{
            title: `Días libres restantes`,
            key: 'diasQuedan',
            render: row => row.user && row.user.diasQuedan
        }]
        this.setState({ columns, extraColLibre })
        if(this.props.dias && this.props.usuarios){
            this.organizarDias()
            const {diasTotales, diasQuedan, diasLibresPendientes} = calcularVacaciones(this.props.auth)
            this.setState({ diasTotales, diasQuedan, diasLibresPendientes })
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.sel1 !== this.state.sel1 || prevState.sel2 !== this.state.sel2){
            this.setState({ diasFilter: this.filtrarDias(this.state.dias) })
        }
        else if((prevProps.dias !== this.props.dias || prevProps.usuarios !== this.props.usuarios) && this.props.dias && this.props.usuarios ){
            this.organizarDias()
            const {diasTotales, diasQuedan, diasLibresPendientes} = calcularVacaciones(this.props.auth)
            this.setState({ diasTotales, diasQuedan, diasLibresPendientes })
        }
    }

    fetchDias = async () => {         
        await this.props.dispatch(fetchCalendario())
        await this.props.dispatch(fetchAuth())
        await this.props.dispatch(fetchUsuarios())
        this.organizarDias()
    }

    organizarDias = () => {
        const { dias } = this.props
        if(dias){
            const festivos = dias.filter(d => d.tipo === "festivo").map(f => ({
                ...f,
                fecha: moment.utc(f.fecha).hour(0).minute(0).seconds(0).milliseconds(0).format()
            }))
            // console.log(festivos)
            const festivosArrUTC = festivos.map(f => moment.utc(f.fecha).hour(2).minute(0).seconds(0).milliseconds(0).format())
            const diasPorAprobar = dias.filter(d => !d.aprobado).map(d => ({...d, key: d._id}))
            let diasLibresPorAprobar = diasPorAprobar.filter(d => d.tipo === "libre")
            const uChachis = this.props.usuarios.filter(u => diasLibresPorAprobar.map(d => d.user._id).includes(u._id)).map(u => {
                const { diasTotales, diasQuedan, diasLibresPendientes } = calcularVacaciones(this.props.auth)
                return ({...u, diasTotales, diasQuedan, diasLibresPendientes })
            })
            diasLibresPorAprobar.forEach((d, i) => {
                diasLibresPorAprobar[i].user = uChachis.find(u => u._id === d.user._id)
            })

            const diasRemotosPorAprobar = diasPorAprobar.filter(d => d.tipo === "remoto")
            this.setState({ 
                dias, 
                festivos,
                festivosArrUTC,
                diasFilter: this.filtrarDias(dias), 
                diasPorAprobar,
                diasLibresPorAprobar,
                diasRemotosPorAprobar
            })
        }
    }
   
    filtrarDias = (dias) => {
        const primerFiltro = this.state.sel1 === "libres" ? dias.filter(d => ["libre", "asuntos"].includes(d.tipo)) : dias.filter(d => d.tipo === "remoto")
        const diasFilter = this.state.sel2 === "mios" ? primerFiltro.filter(d => d.user._id === getUserInfo()._id ) : primerFiltro                                          
        return diasFilter
    }

    responderSolicitud = (dia, bool) => {
        if(bool){ //Aceptar
            request("/dias/" + dia._id, {
                method: "PUT",
                body: { aprobado: true }
            }).then(resp => {
                message.success(`Se ha aprobado la solicitud de ${dia.user.username} de día ${dia.tipo} el día ${moment(dia.fecha).format("YYYY-MM-DD")}`)
                this.fetchDias()
            }).catch(err => {
                console.error(err)
                message.error(`Se ha producido un error durante la aprobación del día ${dia.tipo} solicitado`)                    
            })
        }
        else{ //Denegar
            request("/dias/" + dia._id, {
                method: "DELETE",
            }).then(resp => {
                message.info("Se ha denegado la solicitud de día " + dia.tipo)
                this.fetchDias()    
            }).catch(err => {
                message.error(`Se ha producido un error durante la denegación del día ${dia.tipo} solicitado`)       
                console.error(err)
            })
        }
    }

    getListData = value => {
        const listData = this.state.diasFilter
            .filter(d => moment(d.fecha).format("YYYYMMDD") === moment(value).format("YYYYMMDD"))
            .map(d => ({
                user: d.user.username,
                aprobado: d.aprobado,
                tipo: d.tipo,
                _id: d._id,
                comentario: d.comentario,
                fecha: moment(d.fecha),
                avatar: d.user.avatar && (process.env.REACT_APP_API_URL + d.user.avatar.url)
            }))
        return listData || [];
    }

    dateFullCellRender = value => {
        const listData = this.getListData(value);
        const valueUTC = moment.utc(value.hour(2).minute(0).seconds(0).milliseconds(0))
        const festivo = this.state.festivos.find(f => moment.utc(f.fecha).isSame(valueUTC, "day"))

        const tc = {
            title: festivo ? festivo.nombre : value.format("LL"),
            color: festivo ? "rgba(255, 40, 30, 0.5)" : ( [0, 6].includes(value.day()) ? "rgba(255, 200, 100, 0.5)" : "transparent" )
        }

        return (
            <div className="ant-fullcalendar-date" style={{ backgroundColor: tc.color, margin: "0", borderRadius: 3, borderLeft: "1px solid gainsboro" }} title={tc.title} onClick={evt => this.handleCellClick(evt, value)}>
                <div className="ant-fullcalendar-value">{ value.date() }</div>
                <div className="ant-fullcalendar-content">
                    <ul className="events" style={{ margin: 0, padding: 2 }}>
                    {
                        listData.map(item => {
                            return (
                            <Popover 
                                className={!item.aprobado ? "sinactimel" : ""} 
                                key={item.user + item.fecha} 
                                content={
                                    <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                                        <span>{item.user}</span>
                                        { (!item.aprobado && getUserInfo().manager) && 
                                            <div style={{ display: "flex", marginTop: "1em" }}>
                                                <Popconfirm title={`¿Estás seguro de que deseas aceptar la solicitud de día ${item.tipo} para el día ${moment(item.fecha).format("YYYY-MM-DD")} a ${item.user}?`} onConfirm={evt => this.responderSolicitud(item, true)}>
                                                    <Tag color="green" key={`${item._id}_aceptar`}>Aprobar</Tag>
                                                </Popconfirm>
                                                <Popconfirm title={`¿Estás seguro de que deseas denegar la solicitud de día ${item.tipo} para el día ${moment(item.fecha).format("YYYY-MM-DD")} a ${item.user}?`} onConfirm={evt => this.responderSolicitud(item, false)}>
                                                    <Tag color="volcano" key={`${item._id}_denegar`}>Denegar</Tag>
                                                </Popconfirm>
                                            </div>
                                        }
                                    </div>
                                }
                            >
                                <Avatar onClick={() => this.setState({ modalInfoDia: true, infoDia: item })} style={{ margin: 1, border: item.tipo === "asuntos" ? "2px solid red" : "" }} src={item.avatar ? item.avatar : null}>{ item.user && item.user[0].toUpperCase() }</Avatar>
                            </Popover>
                        )})
                    }
                    </ul>
                </div>
            </div>
        );
    }

    handleCellClick = (evt, value) => {
        evt.persist()
        if(!["span", "img", "button"].includes(evt._targetInst.elementType) && ![...evt._targetInst.stateNode.classList].includes("ant-tag")){
            this.lanzarModalDia(value)
        }
    }

    solicitarDia = (fecha, tipo) => {
        const { _id, equipo } = getUserInfo()
        const comentario = this.comDiaPedido.current.value
        if((comentario === "" || comentario === undefined) && ["remoto", "asuntos"].includes(this.state.tipoDiaPedido)){
            message.warning("Hay que añadir un comentario para solicitar un día remoto (el horario estimado) o de asuntos propios (razón de la ausencia)")
            this.comDiaPedido.current.value = this.props.config[0].comentarioDefault
        }
        else if(this.state.blockSolicitar){
            return
        }
        else{
            this.setState({ blockSolicitar: true })
            fetch(process.env.REACT_APP_API_URL + "/dias", {
                method: "POST",
                headers: getHeaders(),
                body: JSON.stringify({
                    fecha, tipo, 
                    user: _id, 
                    aprobado: getUserInfo().manager,
                    comentario,
                    equipo
                })
            }).then(resp => {
                resp.json().then(dia => {
                    if(dia.aprobado){
                        message.info(`Te has asignado como día ${tipo} el ${moment(this.state.dia).format("DD/MM/YYYY")}`); 
                    }
                    else{
                        message.info(`Has solicitado como día ${tipo === "asuntos" ? "de asuntos propios" : tipo} el ${moment(this.state.dia).format("DD/MM/YYYY")}. Tu solicitud está pendiente de aprobación`); 
                    }
                    this.fetchDias()
                    this.setState({ modalPedirDia: false, dias: [...this.state.dias, dia] })
                }).catch(err => console.log(err))
            }).catch(err => console.log(err))
            this.setState({ blockSolicitar: false })
        }
    }

    lanzarModalDia = dia => {
        const misDias = this.state.dias.filter(d => d.user && (d.user._id === getUserInfo()._id ))
        if( moment().isSameOrBefore(dia, "day") ){
            if( this.state.festivos.find(f => moment(f.fecha).isSame(dia, "day")) === undefined ){
                const pedido = misDias.find(d => moment(d.fecha).isSame(dia, "day"))
                if(!pedido){
                    this.setState({
                        dia,
                        modalPedirDia: true
                    })
                }
                else{ 
                    Modal.confirm({
                        content: `¿Deseas arrepentirte de tu solicitud de día ${pedido.tipo === "asuntos" ? "de asuntos propios" : pedido.tipo} del ${moment(pedido.fecha).format("YYYY-MM-DD")}? La solicitud ${!pedido.aprobado ? "aún no" : "ya"} ha sido aprobada`,
                        getContainer: () => document.body,
                        onOk: () => {
                            request("/dias/" + pedido._id, {
                                method: "DELETE"
                            }).then(resp => {
                                this.fetchDias()
                                message.success("Te has arrepentido satisfactoriamente")
                            })
                            .catch(err => message.error("Ha ocurrido un error en lo de arrepentirse"))
                        }
                    })
                }
            }
            else{ message.warning("No puedes solicitar un día festivo") }
        }
        else{ message.warning("No puedes solicitar un día pasado") }
    }

    render(){
        return(
            <Layout style={{height:"100vh"}}>
                <Frame isLogged={ getToken() ? true : false }>
                    <Row style={{ justifyContent: "space-around", display: "flex", marginBottom: 20 }}>
                        <Statistic title={"Días de vacaciones"} value={this.state.diasTotales} />
                        <Statistic title={"Días de vacaciones restantes"} value={ Number.isNaN(this.state.diasQuedan) ? 0 : this.state.diasQuedan } />
                        <Statistic title={"Días de vacaciones pendientes de aprobar"} value={this.state.diasLibresPendientes} />
                    </Row>
                    <Row style={{ justifyContent: "space-around", display: "flex"}}>
                        <Radio.Group defaultValue={this.state.sel1} buttonStyle="solid">
                            <Radio.Button onClick={() => this.setState({ sel1: "libres" }) } value="libres">Libres</Radio.Button>
                            <Radio.Button onClick={() => this.setState({ sel1: "remotos" }) } value="remotos">Remotos</Radio.Button>
                        </Radio.Group>
                        <Radio.Group defaultValue={this.state.sel2} buttonStyle="solid">
                            <Radio.Button onClick={() => this.setState({ sel2: "global" }) } value="global">Global</Radio.Button>
                            <Radio.Button onClick={() => this.setState({ sel2: "mios" }) } value="mios">Mis días</Radio.Button>
                        </Radio.Group>
                    </Row>
                    <Calendar
                        dateFullCellRender={this.dateFullCellRender}
                        style={{ margin: "-1px 0"}}
                    />
                    
                    <PrivateComponent blue={this.props.blueCollar}>
                        <h1 style={{ marginTop: 20 }}>Días libres pendientes de revisión</h1>
                        <Table dataSource={this.state.diasLibresPorAprobar} columns={[...this.state.columns.slice(0, -1), ...this.state.extraColLibre, ...this.state.columns.slice(-1)] || []} />

                        <h1 style={{ marginTop: 20 }}>Días remotos pendientes de revisión</h1>
                        <Table dataSource={this.state.diasRemotosPorAprobar} columns={this.state.columns || []} />
                    </PrivateComponent>
                </Frame>
                <Modal
                    visible={this.state.modalPedirDia}
                    onCancel={() => this.setState({ modalPedirDia: false })}
                    footer={[
                        <Button key="remoto" type="primary" onClick={() => this.solicitarDia( this.state.dia, this.state.tipoDiaPedido)}>Solicitar Día</Button>
                      ]}
                >
                    <p>Estás solicitando el día { moment(this.state.dia).format("DD/MM/YYYY") }</p>
                    <Form.Item label="Tipo de día">
                        <Radio.Group onChange={e => this.setState({ tipoDiaPedido: e.target.value })} defaultValue="libre">
                            <Radio.Button value="libre">Libre</Radio.Button>
                            <Radio.Button value="remoto">Remoto</Radio.Button>
                            <Radio.Button value="asuntos">Asuntos propios</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="Comentario">
                        <textarea style={{ width: "100%", borderRadius: 5, borderColor: "gainsboro" }} ref={this.comDiaPedido} />
                    </Form.Item>
                </Modal>
                <Modal
                    visible={this.state.modalInfoDia}
                    datos={this.state.infoDia}
                    onCancel={() => this.setState({ modalInfoDia: false })}
                    footer={<Button onClick={() => this.setState({ modalInfoDia: false })}>Cerrar</Button>}
                >
                    <p>Usuario: { this.state.infoDia.user }</p>
                    <p>Fecha: { moment(this.state.infoDia.fecha).format("YYYY-MM-DD") }</p>
                    <p>Tipo: { this.state.infoDia.tipo === "asuntos" ? "Asuntos Propios" : mayusculizer(this.state.infoDia.tipo) }</p>
                    <p>Aprobado: { this.state.infoDia.aprobado ? <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a"/> : <Icon type="close-circle" theme="twoTone" twoToneColor="#eb2f96"/> }</p>
                    <p>Nota: { this.state.infoDia.comentario }</p>
                </Modal>
            </Layout>
        )
    }
}

const mapStateToProps = state => {
    return {
        dias: state.dias,
        auth: state.auth,
        blueCollar: state.blueCollar,
        usuarios: state.usuarios,
        config: state.config
    }
}

export default connect(mapStateToProps)(Calendario)